.history {
  h2 {
    font-size: 30px;
    line-height: 1.13;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 19px;
    }
  }
  // margin-bottom: 15px;

  p {
    font-size: 20px;
    line-height: 1.13;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    span {
      display: block;
      text-indent: 0;
      padding-left: 30px;
      margin-bottom: 5px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 13px;
        background-color: #00405b;
        width: 8px;
        height: 1px;

        @media (max-width: 768px) {
          top: 7px;
          width: 5px;
          left: 15px;
        }
      }
    }
  }

  margin-bottom: 40px;
}
