.content {
  display: grid;
  grid-template-columns: 300px 1fr;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.head {
  grid-area: 1/1/2/3;
  margin-bottom: 35px;

  @media (max-width: 1150px) {
    margin-bottom: 25px;
  }

  @media (max-width: 1000px) {
    grid-area: 1/1/2/1;
  }
}

.slider {
  grid-area: 2/1/3/2;

  .video_item {
    margin-top: 20px;
    p {
      font-size: 15px;
      line-height: 16px;

      font-weight: 600;
    }
    .image_wrap {
      position: relative;
      margin-bottom: 10px;

      img {
        object-fit: cover;
        margin-bottom: -5px;
      }

      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        background-color: rgba(0, 0, 0, 0.55);

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  @media (max-width: 768px) {
    grid-area: 2/1/3/1;
    margin-bottom: 25px;
  }
}

.filling {
  grid-area: 2/2/3/3;
  padding-left: 26px;

  @media (max-width: 1000px) {
    grid-area: 3/1/4/2;
    padding-left: 0;
  }
}

.back {
  grid-area: 3/2/4/3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  @media (max-width: 1000px) {
    grid-area: 4/1/5/2;
  }
}
