.leftControl {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 122px;
  grid-row-gap: 0px;

  padding: 100px 40px 50px;
  // background-color: #fff;

  position: relative;
  min-height: 100vh;

  // margin-top: 55px;

  // @media (max-width: 1000px) {
  //   // grid-template-columns: 1fr;
  //   grid-column-gap: 72px;

  // }

  z-index: 2;

  @media (max-width: 1000px) {
    // grid-template-columns: 1fr;
    display: flex;
    padding: 100px 80px 50px;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    // grid-template-columns: 1fr;
    display: flex;
    padding: 100px 30px 50px;
  }

  .decorAbout {
    margin-left: -40px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .backButtonWrapper {
    position: relative;
    top: calc(77vh - 110px);
    left: 30px;
  }

  .aside {
    grid-area: 1 / 1 / 2 / 2;
    margin-top: -60px;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .children {
    grid-area: 1 / 2 / 2 / 6;
  }
}
