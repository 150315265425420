.text {
  margin-bottom: 20px;
  a {
    text-decoration: underline;
  }

  p {
    // display: block;
    margin-bottom: 10px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  strong {
    font-weight: 600;
  }

  ul {
    margin-bottom: 10px;
    padding-left: 12px;
    list-style: square;
    li {
      margin-bottom: 5px;

      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  ol {
    margin-bottom: 10px;
    padding-left: 12px;
    li {
      margin-bottom: 5px;

      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  span {
    display: block;
    text-indent: 30px;
    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }
}

.document {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .documentList {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;

    padding-right: 25px;

    width: auto;
  }
  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #92a7b1;

    position: relative;

    text-decoration: underline;

    &:hover {
      font-weight: 600;
      color: #7a8c94;
    }

    @media (max-width: 768px) {
      white-space: wrap;
    }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;
    //   background-color: #00405b;

    //   left: 0;
    //   bottom: -3px;
    // }
  }
}

.copyright {
  // margin-bottom: 6px;

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;

    padding-right: 25px;

    &:last-child {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: #92a7b1;

      position: relative;
    }
  }
}
