.button {
  position: absolute;
  bottom: 25px;
  right: 30px;
  width: auto;
  background-color: transparent;

  font-family: solomon;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
  color: #00405b;

  transition: all 0.3s ease;

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='11' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.7 1 1.4 9.3l8.3 8.3' stroke='%23FF2E17' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    position: absolute;
    left: -15px;
    top: 1px;

    transition: all 0.3s ease;
  }

  &::after {
    content: attr(data-text);
  }

  &:hover {
    &::before {
      left: -25px;
      transform: scale(0.95);
    }
  }

  @media (max-width: 1000px) {
    bottom: 12px;
    right: 18px;

    width: 32px;
    height: 32px;

    &.open {
      transform: rotate(180deg);
    }

    &::before {
      display: none;
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='33' height='33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='32.1' y='.5' width='31.8' height='31.8' rx='15.9' transform='rotate(90 32.1 .5)' fill='%2392A7B1'/%3E%3Cpath d='m16.3 10.9 6.5 9.1H9.6l6.7-9.1Z' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}
