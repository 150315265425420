.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  overflow: scroll;
  overflow-x: hidden;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.content {
  margin-top: 300px;
  padding: 50px 40px;
  border-radius: 5px;
  background-color: #fff;

  transform: scale(0.5);
  transition: 0.4s all;
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  overflow: scroll;

  position: relative;

  &.active {
    transform: scale(1);
  }

  .close {
    width: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
