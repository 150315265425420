.territory {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.13;

    margin-bottom: 16px;
  }
  label {
    margin-bottom: 6px;
    cursor: pointer;

    display: flex;
    flex-wrap: nowrap;
  }

  .mark {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #e5e3e6;
    transition: all 0.15s ease;
  }

  input[type="checkbox"]:checked {
    & + .mark {
      background-color: #ff2e17;
      // outline: 1px solid red;
    }
  }

  .name {
    display: inline-block;
    font-size: 17px;
    line-height: 1.13;
    width: auto;
    margin-left: 11px;
    user-select: none;
  }

  &.modal {
    label {
      margin-bottom: 16px;
    }

    .mark {
      width: 14px;
      height: 14px;
    }
  }

  .selectWrapper {
    position: relative;
    input {
      font-size: 14px;
      font-weight: 500;
      padding: 14px;
      padding-right: 33px;
      border: 2px solid #00405b;
      font-family: "Solomon";

      &::placeholder {
        font-family: "Solomon";
        font-size: 14px;
        font-weight: 500;
        color: #92a7b1;
      }
    }

    .decorArrow {
      position: absolute;
      right: 16px;
      top: 18px;
      width: 14px;
      height: 14px;
    }

    ul {
      position: absolute;
      z-index: 2;

      background-color: #fff;

      border: 2px solid #00405b;
      border-top: none;

      overflow-y: auto;
      max-height: 212px;
      li {
        padding: 14px 6px;
        font-size: 14px;
        font-weight: 500;
        color: #00405b;

        cursor: pointer;

        &:hover {
          background-color: rgba(146, 167, 177, 0.15);
        }
      }
    }
  }
}
