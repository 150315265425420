.slick-next:before,
.slick-prev:before {
  //   color: #00405b;
}

#newsSlider .slick-next {
  right: calc(50% - 30px);
  top: 83%;

  &::before {
    background-image: url('../../../images/arrow-right-slider-news.svg');
    opacity: 1;
  }
}
#newsSlider .slick-prev {
  left: calc(50% - 30px);
  top: 83%;
  &::before {
    background-image: url('../../../images/arrow-left-slider-news.svg');
    opacity: 1;
  }
  z-index: 3;
}
