.item {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 30px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    // grid-template-columns: 1fr;
  }
}

.head {
  grid-area: 1/1/2/6;

  margin-bottom: 32px;

  @media (max-width: 1000px) {
    // grid-area: 1/1/2/2;
    margin-bottom: 20px !important;
  }
}

.slider {
  grid-area: 2/1/3/4;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    // grid-area: 2/1/3/2;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.info {
  grid-area: 2/4/3/6;

  @media (max-width: 1000px) {
    // grid-area: 3/1/4/2;
  }
}

.button_back {
  grid-area: 5/5/6/6;

  text-align: right;
  margin-top: 20px;

  @media (max-width: 1000px) {
    grid-area: none;
  }
}

.video {
  align-self: flex-end;
  justify-self: flex-end;
  flex: 0 1 auto;

  text-align: right;

  width: auto;
  white-space: nowrap;

  color: #00405b;
  font-size: 18px;
  line-height: 1.5;
  position: relative;

  margin-left: 45px;

  @media (max-width: 1150px) {
    // justify-self: flex-start;
    align-self: flex-start;
    margin-left: 45px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    margin-left: 45px;
  }

  &:hover {
    &::before {
      transform: translateY(-50%) rotate(120deg);
    }
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='31' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.3' cy='15.2' r='14.8' fill='%2392A7B1'/%3E%3Cpath d='M24.3 15.2 10 23.5V7l14.3 8.2Z' fill='%23fff'/%3E%3C/svg%3E");
    position: absolute;
    left: -45px;
    top: 45%;

    width: 30px;
    height: 30px;

    transform: translateY(-50%);

    transition: transform 0.3s ease;
  }
}
