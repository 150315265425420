.workersWrapper {
  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(208px, 1fr));
    column-gap: 51px;

    @media (max-width: 1000px) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
    }
  }
}
