.item {
  @media (max-width: 768px) {
    margin: auto;
    background-color: red;
  }
  li {
    // border: 1px solid #00405b;
    width: 100%;
    height: 189px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    // outline: 1px solid red;
    padding: 20px 25px;

    position: relative;
    z-index: 1;
    // overflow: visible;

    img {
      height: auto;
      max-width: 160px;
    }

    &:hover {

      img {
        filter: grayscale(0);
      }

      &::after {
        transform: translate(-7px, 7px);
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: -3;
      border: 1px solid #00405b;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: #92a7b1;
      z-index: -5;
      transition: all 0.2s ease;
    }
  }

  img {
    filter: grayscale(1);
    transition: filter 0.3s ease;
  }
}

.activeItem {
  li {
    // border: 1px solid #00405b;
    width: 100%;
    height: 189px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    // outline: 1px solid red;
    padding: 20px 25px;

    position: relative;
    z-index: 1;
    // overflow: visible;
    img {
      height: auto;
      max-width: 160px;
      // width: auto; 
    }

      img {
        filter: grayscale(0);
      }

      &::after {
        transform: translate(-7px, 7px);
      }

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: -3;
      border: 1px solid #00405b;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: #92a7b1;
      z-index: -5;
      transition: all 0.2s ease;
    }
  }

  img {
    filter: grayscale(1);
    transition: filter 0.3s ease;
  }
}

