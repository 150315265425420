.agr {
  & > p {
    margin-bottom: 23px;

    font-size: 18px;
    line-height: 1.13;

    @media (min-width: 1000px) {
      min-height: 150px;
    }

    &::after {
      content: "";
      position: relative;
      display: none;
      bottom: -25px;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #00405b;
    }

    @media (max-width: 768px) {
      margin-bottom: 40px;
      font-size: 16px;

      &::after {
        display: block;
      }
    }
  }
}

.search {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 0;
  }

  .registryFilterWrap {
    // box-sizing: border-box;
    flex: 1;
    border: 2px solid #00405b;
    // outline: 2px solid #;
    
    font-weight: 400;
    font-size: 18px;
    color: #00405b;

    position: relative;

    @media (max-width: 768px) {
      border: 1px solid transparent;
      outline: 1px solid #00405b;
    }

    // width: 300px;

    p {
      width: 100%;
      z-index: 3;
      background-color: #fff;
      padding: 13px 35px 13px 17px;

      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
      }

      @media (max-width: 768px) {
        padding: 7px 13px;
      }
    }

    svg {
      position: absolute;
      top: calc(50% - 8px);
      right: 18px;

      width: 18px;

      transition: .2s;
      cursor: pointer;

    }

    ul {
      position: absolute;
      bottom: 1px;
      left: 0px;

      padding: 9px 0;

      background-color: #ffffff;

      width: calc(100%);

      border: 1.5px solid transparent;
      outline: 2px solid #00405b;
      border-top: none;

      z-index: 1;

      transform: translateY(calc(100% + 3px));

      @media (max-width: 768px) {
        border: 1px solid transparent;
        outline: 1px solid #00405b;

        transform: translateY(calc(100% + 2px));
        width: calc(100% + 2px);

        left: -1px;
      }

      li {
        padding: 9px 0 9px 17px;
        line-height: 1;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #EFF2F4;
        }

        div {
          width: 11px;
          height: 11px;
          background-color: #E5E3E6;
          border-radius: 50%;

          margin-right: 17px;
        }
      }
    }
  }
}

