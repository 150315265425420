.content {
  h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.info {
  margin-bottom: 12px;
  @media (max-width: 1000px) {
    // margin-bottom: 0;
  }
  ul {
    padding-left: 15px;
  }

  li {
    &::before {
      content: '';
      position: relative;
      display: block;
      left: -15px;
      top: 14px;
      width: 7px;
      height: 7px;
      background-color: #ff2e17;
      border-radius: 50%;
    }
  }

  p,
  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
  }
}

.documentsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-area: 4/1/5/5;
  margin-top: 20px;

  h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  a {
    display: inline-block;
    margin-bottom: 5px;
    text-decoration: underline;
  }
}

@media (min-width: 1000px) {
  .desc {
    grid-area: 3/1/4/6;

    h3 {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 5px;
    }
  }
}

.documentsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-area: 4/1/5/5;
  margin-top: 20px;

  h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  a {
    display: inline-block;
    margin-bottom: 5px;
    text-decoration: underline;
  }
}

.desc {
  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    white-space: pre-wrap;
  }
  // span {
  //   display: block;
  //   margin-bottom: 15px;
  // }
}

.news {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  a {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #92a7b1;

    position: relative;

    text-decoration: underline;

    &:hover {
      font-weight: 600;
      color: #7a8c94;
    }

    @media (max-width: 768px) {
      white-space: wrap;
    }
  }
}
