a {
  color: inherit;
}

.item {
  display: flex;
  margin-bottom: 30px;
  gap: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 10px;
  }
  img {
    min-width: 270px;
    max-width: 270px;
    height: 171px;
    object-fit: cover;
    object-position: center;

    @media (max-width: 1000px) {
      max-width: 1000px;
      width: 100%;
      height: auto;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 1000px) {
      gap: 10px;
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      p {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;

        @media (max-width: 1000px) {
          margin-bottom: 10px;
        }
      }
      span {
        text-align: right;
        font-size: 20px;
        font-weight: 600;

        @media (max-width: 1000px) {
          text-align-last: left;
        }
        &.currentContest {
          color: #ff2e17;
        }
      }
    }

    .description {
      font-size: 20px;
      line-height: 30px;
      text-align: justify;

      @media (max-width: 1000px) {
        line-height: 24px;
      }
    }
  }
}
