// Слайдер на главной старнице
[class*="SliderMission"] {
  .slick-dots {
    text-align: left;
    position: relative;
    margin-top: 15px;

    bottom: 0;

    li {
      width: unset;
      height: unset;
      margin: 0;
      margin-right: 11px;

      button {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid white;
        transition: all 0.3s ease;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: #ff2e17;
          border-radius: 50%;
          border: 1px solid transparent;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    // display: none;
  }
}
