.programm {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.13;

    margin-bottom: 16px;
  }
  label {
    margin-bottom: 6px;
    cursor: pointer;

    display: flex;
    flex-wrap: nowrap;
  }

  .mark {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #e5e3e6;
    transition: all 0.15s ease;
  }

  input[type="checkbox"]:checked {
    & + .mark {
      background-color: #ff2e17;
      // outline: 1px solid red;
    }
  }

  .name {
    display: inline-block;
    font-size: 17px;
    line-height: 1.13;
    width: auto;
    margin-left: 11px;
    user-select: none;
  }

  &.modal {
    label {
      margin-bottom: 16px;
    }

    .mark {
      width: 14px;
      height: 14px;
    }
  }
}
