.item {
  margin-bottom: 25px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
}

a {
  &.pressed button {
    background-color: #00405b;
    color: white;
  }
}

.button {
  background-color: white;
  border: 2px solid #00405b;

  // padding: 15px 70px 13px;
  padding-top: 15px;
  padding-bottom: 13px;

  font-family: solomon;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.13;
  color: #00405b;

  width: 100%;

  @media (max-width: 768px) {
    padding-top: 11px;
    padding-bottom: 9px;
  }

  &:hover {
    background-color: #00405b;
    color: white;
  }

  &:active {
    color: #ffffff80;
  }

  &.pressed {
    background-color: #00405b;
    color: white;
  }
}
