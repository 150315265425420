.rightControl {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 5vw;
  row-gap: 80px;

  padding: 10px 40px 50px;
  background-color: #fff;
  height: 100%;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: 100px 80px 50px;
    row-gap: 0px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 100px 30px 50px;
    row-gap: 0px;
  }
}

.search {
  // grid-area: 1/1/2/3;
  display: flex;
  justify-content: space-between;
  // text-align: right;
  align-items: center;
  padding: 0 40px;
  margin-top: 40px;

  @media (max-width: 1000px) {
    // grid-area: 1/1/2/2;
    margin-top: 0;
  }
}

.content {
  grid-area: 1 / 2 / 2 / 6;

  @media (max-width: 900px) {
    grid-area: 3/1/4/2;
  }
}

.aside {
  grid-area: 1 / 1 / 2 / 2;

  @media (max-width: 1000px) {
    // grid-area: 2/1/3/2;
    width: 100%;
  }
}
