.slider {
  position: absolute;
  bottom: 10vh;
  right: 58px;
  bottom: 45px;

  background: rgba(0, 17, 24, 0.38);
  backdrop-filter: blur(3px);

  padding: 15px 15px 20px 15px;
  border-radius: 7px;

  width: 350px;

  // @media (max-width: 1150px) {
  //   left: calc(5vw + 50px);
  // }

  @media (max-width: 1000px) {
    display: none;
  }

  h2 {
    font-size: 28px;
    line-height: 1.5;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: inline-block;
    width: auto;

    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ff2e17;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25;
    color: white;

    padding-right: 10px;
  }

  &.menu {
    display: none;
    // text-transform: unset !important;
    // font-size: 80px;
    // line-height: 100%;
    left: 5vw;

    bottom: 6vh;
    width: 90%;

    @media (max-width: 1000px) {
      display: block;
      margin-bottom: -33px;
    }

    // padding-right: 5vw;

    h2 {
      text-transform: none;
      margin-bottom: 0;
      line-height: 1.1;

      &::after {
        display: none;
      }
    }
  }
}
