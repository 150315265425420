.text {
  padding-top: 100px;
  width: 90%;
  margin: 0 auto;

  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  ul {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  table {
    margin-bottom: 20px;
    border-collapse: collapse;
    border-spacing: 0;
    th {
      width: 50%;
      padding: 10px;
      border: 1px solid #004669;
    }
    td {
      width: 50%;
      padding: 10px;
      border: 1px solid #004669;

      ul {
        padding-left: 30px;
        li {
          list-style-type: disc;
        }
      }
    }
  }
}

.link {
  text-decoration: underline;
}

.mb {
  margin-bottom: 20px;
}

a[href='mailto:v.razorenova@ingorod24.ru'] {
  text-decoration: underline;
}
