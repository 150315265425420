.item {
  margin-top: 40px;
  margin-bottom: 85px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
  .head {
    display: flex;
    gap: 30px;

    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column-reverse;
    }

    .slider_photos {
      flex: 1;
      width: 50%;
      margin-bottom: 60px;
      @media (max-width: 1000px) {
        width: 100%;
        margin-bottom: 30px;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;

        cursor: pointer;
      }
    }
    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .place {
        padding: 6px 20px;
        background-color: rgba(0, 70, 105, 1);

        font-size: 25px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        margin-bottom: 25px;

        @media (max-width: 1000px) {
          font-size: 16px;
          line-height: 18px;

          margin-left: 1px;
        }
      }
      .short_description {
        h3 {
          font-size: 25px;
          text-transform: uppercase;
          text-decoration: underline;
          color: rgba(0, 70, 105, 1);
          font-weight: bold;

          @media (max-width: 1000px) {
            font-size: 16px;
            line-height: 18px;
            margin: 0;
            margin-bottom: 8px;
          }
        }
        p,
        span {
          font-size: 20px;
          line-height: 1.2;
          color: rgba(107, 133, 158, 1);
          @media (max-width: 1000px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
        strong {
          color: rgba(0, 70, 105, 1);
          font-weight: bold;
          @media (max-width: 1000px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }
  .description {
    h3 {
      font-size: 25px;
      text-transform: uppercase;
      text-decoration: underline;
      color: rgba(0, 70, 105, 1);
      font-weight: bold;

      @media (max-width: 1000px) {
        font-size: 16px;
        line-height: 18px;
        margin: 0;
        margin-bottom: 8px;
      }
    }
    p {
      font-size: 20px;
      line-height: 1.2;
      color: rgba(107, 133, 158, 1);
      @media (max-width: 1000px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    strong {
      color: rgba(0, 70, 105, 1);
      font-weight: bold;
      @media (max-width: 1000px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  .documents {
    margin-top: 40px;
    a {
      font-size: 24px;
      font-weight: 300;
      color: rgba(0, 70, 105, 1);
      text-decoration: none;
      padding-left: 30px;

      margin-right: 40px;
      margin-bottom: 10px;

      position: relative;

      @media (max-width: 1000px) {
        font-size: 16px;
        line-height: 18px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        transition: 0.3s;
        left: 8px;
        top: calc(50% - 10px);
        width: 16px;
        height: 16px;
        border: 2px solid #cc2229;
        border-radius: 50%;

        box-sizing: border-box;
      }

      &:hover::before {
        background-color: #cc2229;
      }
    }
  }
}
