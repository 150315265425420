.item {
  // position: relative;
  // outline: 1px solid red;

  // width: 330px;
  // height: 220px;

  @media (min-width: 768px) and (max-width: 1000px) {
    width: 100%;
  }

  & > a {
    position: relative;
    display: block;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80%;
      // outline: 1px solid green;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(204, 204, 204, 0.04383760340073528) 80%,
        rgba(255, 255, 255, 0) 100%
      );

      transition: all 0.3s ease;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20%;
      // outline: 1px solid green;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

      transition: all 0.3s ease;
    }

    &:hover {
      &::before {
        transform: translateY(-40px);
      }
    }
  }
}

.title {
  position: absolute;
  padding: 10px;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;

  @media (max-width: 1000px) {
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    font-weight: 600;
  }
}

.image {
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // width: 330px;
  height: 220px;

  @media (max-width: 1000px) {
    height: 220px;
  }

  @media (max-width: 700px) {
    height: 161px;
  }

  @media (min-width: 1800px) {
    height: 280px;
  }
}

.skeleton {
  background-color: gray;
}

.date {
  position: absolute;

  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
  color: #fff;
  // padding-bottom: 10px;
  bottom: 5px;
  text-align: right;
  right: 10px;

  z-index: 3;
  // left: 0;
  // outline: 1px solid red;

  @media (max-width: 1000px) {
    font-size: 24px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 500;
  }
}
