.links_wrap {
  // padding-left: 70px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  @media (max-width: 768px) {
    margin-top: 60px;
    padding-left: 0;
  }
  a {
    display: block;
    color: #00405b;
    padding-left: 28px;
    position: relative;

    &:hover {
      color: #00405b;
    }

    &:first-child {
      margin-bottom: 20px;
    }

    &.next {
      div {
        content: url("data:image/svg+xml,%3Csvg width='19' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9.5' cy='9.5' r='9.5' fill='%2300405B'/%3E%3Cpath d='M11.4 5 7 9.6l4.4 4.4' stroke='%23fff' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        position: absolute;
        transform: rotate(180deg);

        width: 19px;
        height: 19px;

        left: 0px;
        top: 0px;
      }
    }

    &.prev {
      div {
        content: url("data:image/svg+xml,%3Csvg width='19' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9.5' cy='9.5' r='9.5' fill='%2300405B'/%3E%3Cpath d='M11.4 5 7 9.6l4.4 4.4' stroke='%23fff' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        position: absolute;
        transform: rotate(180deg);

        width: 19px;
        height: 19px;

        left: 0px;
        transform: rotate(0deg);

        top: 0px;
      }
    }
  }
}
