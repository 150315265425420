.desc {
  display: flex;
  flex-direction: column;
}

.item {
  padding: 18px 10px 8px;
  // background-color: #e1e7e9;
  background-color: #fff;
  display: block;

  @media (max-width: 768px) {
    padding: 12px 15px 10px;
    margin-bottom: 0;
  }

  &:hover {
    background-color: #e1e7e9;
  }

  &.pressed {
    background-color: #e1e7e9;
  }

  h3 {
    font-size: 23px;
    line-height: 1.13;
    font-weight: 600;
    margin-bottom: 13px;

    @media (max-width: 768px) {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  p {
    color: #00405b;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
