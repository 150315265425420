.nav {
  padding-top: 30px;
  padding-right: 43px;
  position: relative;

  @media (max-width: 1200px) {
    display: none;
  }

  ul {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }

  &.open {
    display: block;
    // height: 100%;
    // outline: 1px solid blue;
    position: absolute;
    top: 130px;
    right: 0;
    width: auto;
    padding: 0;
    background-color: #00405b;
    // transform: translateY(-50%);

    ul {
      align-items: end;
    }

    @media (max-width: 500px) {
      top: 115px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 1px;
      background-color: #fff;
      right: 0;
    }

    &::before {
      top: -15px;
    }
    &::after {
      bottom: -15px;
    }

    ul {
      // background-color: green;
      flex-direction: column;
      align-items: flex;

      li {
        margin: 12px 0;
        border: none;
        text-align: right;
        display: flex;
        justify-content: end;
      }

      a {
        color: #ffffff;
      }
    }
  }
}
