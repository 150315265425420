.list {
  @media (max-width: 1000px) {
    position: relative;
    margin-bottom: 50px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -17px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #00405b;
    }
  }
}

.item {
  margin-bottom: 24px;
}

.button {
  background-color: #fff;
  border: 2px solid #00405b;
  padding-top: 15px;
  padding-bottom: 15px;

  font-weight: 600;
  font-size: 16px;
  line-height: 1.13;
  color: #00405b;

  margin-bottom: 0px;
  transition: all 0.3s ease;

  @media (min-width: 1000px) {
    &:hover {
      color: #ffffff;
      background-color: #00405b;
    }
  }

  // @media (max-width: 768px) {
  //   &:hover {
  //     color: #00405b;
  //     background-color: #fff;
  //   }
  // }

  &.pressed {
    color: #ffffff;
    background-color: #00405b;
    margin-bottom: 20px;

    & + .filterbox {
      transform: scale(1);
      height: auto;
    }
  }
  &.pressedWithoutFilterbox {
    color: #ffffff !important;
    background-color: #00405b !important;
    margin-bottom: 0px;

    & + .filterbox {
      transform: scale(0);
      height: 0;
    }
  }

  &:active {
    color: #ffffff90;
  }
}
.filterbox {
  transform: scale(0);
  height: 0;
  // margin-bottom: 0;
}

.filteritem {
  margin-bottom: 10px;

  label {
    margin-bottom: 6px;
    cursor: pointer;

    display: flex;
    flex-wrap: nowrap;
  }

  .mark {
    display: inline-block;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    background-color: #e5e3e6;
    transition: all 0.15s ease;
  }

  input[type="checkbox"]:checked {
    & + .mark {
      background-color: #ff2e17;
      // outline: 1px solid red;
    }
  }

  .name {
    display: inline-block;

    font-size: 17px;
    font-weight: 500;
    line-height: 1.13;
    width: auto;
    margin-left: 11px;
    user-select: none;
  }
}
