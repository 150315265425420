.head {
  background-color: #92a7b1;
  display: flex;
  color: white;
  font-size: 18px;
  font-weight: 600;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   display: block;
  //   z-index: 3;
  //   width: 2px;
  //   height: 100%;
  //   left: calc(40% - 2px);
  //   top: 0;
  //   // transform: translateY(-50%);
  //   background-color: #fff;
  // }
}

.type {
  flex: 0 0 20%;
  padding: 16px 15px;
  // width: 40%;
  text-align: center;
  position: relative;

  border-right: 2px solid #fff;
}

.name {
  flex: 0 0 40%;
  padding: 16px 15px;

  // width: 60%;
  text-align: center;

  border-right: 2px solid #fff;
}

.documents {
  flex: 0 0 40%;

  text-align: center;

  padding: 16px 15px;
}
