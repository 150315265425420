.item {
  border-bottom: 2px solid #00405b;
  position: relative;

  @media (max-width: 768px) {
    border: 2px solid #00405b;
    margin-bottom: 40px;
  }

  &:last-child {
    border-bottom: none;

    @media (max-width: 768px) {
      border-bottom: 2px solid #00405b;
    }
  }
}
