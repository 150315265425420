.head {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  min-height: 141px;

  @media (max-width: 768px) {
    min-height: auto;
  }
}

.title {
  font-size: 35px;
  font-weight: 600;
  line-height: 1.15;

  margin-bottom: 6px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 3px;
  }
}

.street {
  font-size: 21px;
  line-height: 1.13;
  color: #92a7b2;
  margin-bottom: 12px;

  @media (max-width: 1150px) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.video {
  align-self: flex-end;
  justify-self: flex-end;
  flex: 0 1 auto;

  text-align: right;

  width: auto;
  white-space: nowrap;

  color: #00405b;
  font-size: 18px;
  line-height: 1.5;
  position: relative;

  margin-left: 45px;

  @media (max-width: 1150px) {
    // justify-self: flex-start;
    align-self: flex-start;
    margin-left: 45px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    margin-left: 45px;
  }

  &:hover {
    &::before {
      transform: translateY(-50%) rotate(120deg);
    }
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='31' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.3' cy='15.2' r='14.8' fill='%2392A7B1'/%3E%3Cpath d='M24.3 15.2 10 23.5V7l14.3 8.2Z' fill='%23fff'/%3E%3C/svg%3E");
    position: absolute;
    left: -45px;
    top: 45%;

    width: 30px;
    height: 30px;

    transform: translateY(-50%);

    transition: transform 0.3s ease;
  }
}
