.logo {
  z-index: 200;
  width: 100px;
  margin-top: 24px;
  margin-bottom: 50px;
  @media (max-width: 1000px) {
    margin-top: 0;
    position: fixed;
    top: 30px;
    left: 30px;
    // display: inline;
    width: 50px;
    height: 50px;
  }

  img {
    width: 248px;
    height: 177px;

    @media (max-width: 1000px) {
      width: 50px;
      height: 50px;
    }
  }
}
