.other {
  margin-top: 35px;
  // transform: translateY(200px);

  & > p {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.13;
    position: relative;
    margin-bottom: 35px;

    @media (max-width: 768px) {
      font-size: 19px;
      margin-bottom: 25px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 100%;
      height: 1px;
      background-color: #00405b;
    }
  }
}
