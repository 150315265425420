.sliderWrapper {
  user-select: none;
  position: relative;
}

.item {
  cursor: pointer;
  position: relative;
}

.image {
  height: 50vh;
  object-fit: cover;
}

.author {
  text-align: center;
  text-wrap: balance;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 10px 15px;
}

.prevArrow {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 1%;
  z-index: 1;
}

.nextArrow {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: 1%;
  z-index: 1;
  transform: rotate(180deg);
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    height: auto;
  }

  .close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}
