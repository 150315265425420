.button {
  display: grid;
  grid-template-columns: 20% 30% 20% 15% 15%;
  align-items: center;
  text-align: center;
  background-color: transparent;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .line {
    display: block;
    height: 1px;
    width: 40px;
    background-color: red;

    margin-left: 15px;
    margin-bottom: 15px;
  }

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    grid-area: 1 / 1 / 2 / 5;

    p {
      padding: 20px 0;    
      font-size: 20px;
      text-align: center;
    }
  }

  p {
    width: 100%;
    position: relative;
    font-family: solomon;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.13;
    color: #00405b;
    padding: 0 15px 15px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -1px;
      width: 2px;
      height: calc(150% + 200px);
      background-color: #92a7b1;
      transform: translateY(-50%);

      @media (max-width: 768px) {
        display: none;
      }
    }

    &:last-of-type {
      border-right: none;

      &::after {
        display: none;
      }
    }

    &.name {
      text-align: left;
      @media (max-width: 768px) {
        background-color: #00405b;
        font-weight: 600;
        color: #ffffff;
        padding: 10px 15px;
        text-align: center;
      }
    }

    &.object {
      text-align: left;
      padding-top: 10px;
    }

    &.dates {
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        display: block;
      }

      span {
        @media (max-width: 768px) {
          color: #92a7b1;
        }

        &:nth-of-type(2) {
          color: #ff2e17;
          margin: 5px 0;

          @media (max-width: 768px) {
            color: #92a7b1;
            margin: 0 3px;
          }
        }
      }
    }
  }
}
