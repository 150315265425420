.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.header {
  flex: 1;
  position: relative;
}

.logo {
  cursor: pointer;
  width: 200px;
  position: absolute;
  top: 2%;
  left: 5%;
}

.backButton {
  margin-top: 50px;
  // padding-right: 5%;
  padding-right: 10%;
  margin-bottom: 50px;
}

.allIdeas {
  width: 90%;
  margin: 0 auto;
  color: #004669;
}

.wrapper {
  margin-top: 150px;
}

.placeName {
  font-weight: 600;
  font-size: 26px;
}

.placeAddress {
  font-weight: 500;
  font-size: 20px;
}

.content {
  margin-top: 50px;
  display: flex;
  gap: 100px;
}

.left {
  width: 50%;
  position: relative;

  @media (min-width: 1000px) {
    max-height: 415px !important;
    overflow-y: auto;
    padding-right: 57px;
  }

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: #00405b;
    border-left: 6px solid #ffffff; /* ширина всей полосы прокрутки */
    border-right: 6px solid #ffffff; /* ширина всей полосы прокрутки */
    /* цвет зоны отслеживания */
  }

  &::-webkit-scrollbar-thumb {
    // height: 14px;

    background-color: #92a7b1; /* цвет бегунка */ /* округлось бегунка */
    // border-top: 38px solid red;  /* отступ вокруг бегунка */
    // border-bottom: 38px solid red;  /* отступ вокруг бегунка */
  }
}
.right {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 30px;
}

.title {
  grid-area: 1/1/2/6;
  margin-bottom: 32px;

  h2 {
    font-size: 35px;
    font-weight: 600;
    line-height: 1.15;
    margin-bottom: 6px;
  }
}
.image {
  object-fit: cover;
  grid-area: 2/1/3/4;
  margin-bottom: 40px;
}

.info {
  grid-area: 2/4/3/6;
}

.backButton {
  grid-area: 5/5/6/6;
  text-align: right;
  margin-top: 20px;
}

.idea {
  display: flex;
  align-items: center;
  gap: 50px;
  cursor: pointer;

  padding: 10px 23px;

  font-family: solomon;

  background-color: transparent;

  p {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    color: #00405b;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  img {
    width: 63px;
    height: 63px;
    object-fit: cover;
  }

  &:hover {
    background-color: #eff2f3;
  }
}

.info {
  margin-bottom: 12px;
  @media (max-width: 1000px) {
    // margin-bottom: 0;
  }
  ul {
    padding-left: 15px;
  }

  li {
    &::before {
      content: '';
      position: relative;
      display: block;
      left: -15px;
      top: 14px;
      width: 7px;
      height: 7px;
      background-color: #ff2e17;
      border-radius: 50%;
    }
  }

  p,
  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    margin-top: 200px;
  }
}

@media (max-width: 768px) {
  .right {
    display: flex;
    flex-direction: column;
  }
}
