.search {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-end;
    position: relative;

    margin-bottom: 50px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -17px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #00405B;
    }
  }
}

.input {
  // display: inline;
  max-width: 280px;
  max-height: 52px;
  border: 2px solid #00405b;
  // outline: 2px solid #00405b;
  padding: 13px 22px;

  font-weight: 400;
  font-size: 18px;
  color: #00405b;

  @media (max-width: 1000px) {
    // width: 30vw;
    font-size: 16px;
    padding: 15px 7px;

    margin-bottom: 20px;
    border: 2px solid #00405b;
    outline: none;
    width: 100%;
    max-width: 100%;
  }

  // box-sizing: content-box;

  &::placeholder {
    color: #92a7b1;
  }

  &:focus {
    border-color: #00405b;

    @media (max-width: 1000px) {
      border: 2px solid #00405b;
    }
  }
}

.button {
  // flex: 0 1 auto;
  width: auto;

  background-color: #00405b;
  outline: 2px solid #00405b;

  font-weight: 600;
  font-size: 18px;
  line-height: 1.13;
  color: #ffffff;
  padding: 13px 60px;

  margin-left: 25px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 7px 30px;
  }

  &:active {
    color: #ffffff90;
  }
}
