.filters {
  display: flex;
  width: 100%;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    margin-bottom: 0;
    justify-content: flex-end;
  }
}

.terr {
  flex: 1 0 35%;

  padding-right: 20px;
  margin-bottom: 16px;
  @media (max-width: 1000px) {
    display: none;
  }

  &.modal {
    display: block;
    padding-right: 0;
  }
}

.prog {
  flex: 1 0 40%;
  padding-right: 20px;

  @media (max-width: 1000px) {
    display: none;
  }

  &.modal {
    display: block;
    padding-right: 0;
  }
}

.year {
  flex: 1 0 25%;
  max-width: 200px;

  @media (max-width: 1000px) {
    display: none;
  }

  &.modal {
    display: block;
  }
}

.button_filters {
  display: none;
  width: auto;
  padding: 8px 15px 6px;
  background-color: #00405b;
  margin-right: 35px;
  margin-top: 23px;

  position: relative;
  z-index: 1;

  @media (max-width: 1000px) {
    display: block;
  }

  &:active {
    span {
      color: #ffffff90;

      &::after {
        border-top: 12px solid #ffffff90;
      }
    }
  }

  span {
    font-family: solomon;
    font-size: 16px;
    font-weight: 600;
    color: white;

    &::after {
      content: '';
      position: relative;
      top: 14px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 12px solid #fff;
      margin-left: 7px;
    }
  }
}

.apply {
  width: auto;

  display: inline-block;

  // justify-self: center;
  align-self: center;
  text-align: center;

  font-family: solomon;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  color: #ffffff;

  background-color: #00405b;

  margin-top: 15px;
  padding: 8px 15px 7px;

  &:active {
    color: #ffffff90;
  }

  &:disabled {
    background-color: #00405ba4;
    color: #ffffff90;
  }
}
