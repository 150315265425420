.header {
  min-height: 100vh;
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  cursor: pointer;
  width: 200px;
  position: absolute;
  top: 2%;
  left: 5%;
}

.text {
  position: relative;
  z-index: 10;
  // padding: 15% 5% 50px;
  padding: 15% 10% 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 100vh;
  color: white;

  h1 {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
  }

  p {
    margin-bottom: 10px;
    // text-indent: 3em;
    font-size: 20px;
  }
}

.textWrapper {
  display: flex;
  gap: 100px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;

  h1 {
    font-weight: 700;
    text-align: center;
    text-wrap: balance;
  }

  a {
    text-align: center;
    width: 350px;
    padding: 10px 10px 15px;
    border-radius: 30px;
    color: #004669;
    font-size: 20px;
    font-weight: 600;
    font-family: solomon;
    background: linear-gradient(
      180deg,
      #fff 74.5%,
      #fff 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.text2 {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.partners {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.partner {
  object-fit: contain;
  width: 150px;
}

.backButton {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  // padding-right: 5%;
  padding-right: 10%;
  padding-left: 5%;
  margin-bottom: 50px;
}

@media (max-width: 1550px) {
  .text {
    // padding: 20% 5% 50px;
    padding: 20% 10% 50px;
  }
}
@media (max-width: 1350px) {
  .text {
    // padding: 20% 5% 50px;
    padding: 20% 10% 50px;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }
}

@media (max-width: 1200px) {
  .logo {
    display: none;
  }
  .text {
    // padding: 50px 5% 50px;
    padding: 50px 10% 50px;
  }
}

@media (max-width: 1000px) {
  .text {
    // padding: 100px 5% 0;
    padding: 100px 10% 0;

    h1 {
      font-size: 20px;
    }
    p {
      font-size: 18px;
    }
  }
  .textWrapper {
    gap: 50px;
  }
  .title {
    a {
      width: 250px;
    }
  }
}

@media (max-width: 1000px) {
  .textWrapper {
    display: flex;
    flex-direction: column;
  }
  .text2 {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .backButton {
    gap: 30px;
    flex-direction: column;
  }
}
