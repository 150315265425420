.stageWrapper {
  background-color: #004669;
}

.title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  padding-top: 20px;
  // padding-left: 5%;
  padding-left: 10%;
  padding-bottom: 5px;
  border-bottom: 2px solid white;
}

.stages {
  // width: 90%;
  width: 80%;
  margin: 50px auto 0;
  color: white;
  display: flex;
  justify-content: flex-start;
  gap: 100px;
}

.stage {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}

.stageTitle {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-top: 10px;
}

.numberWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.num {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 5px;
  background: #6b859e;
  color: white;
  text-align: center;
  font-size: 80px;
  font-weight: 700;
}

.numActive {
  background: white;
  color: #004669;
}

.start {
  margin-top: 10px;
}

.desc {
  // width: 90%;

  width: 80%;
  margin: 50px auto;
  color: white;
  font-weight: 600;
  padding-bottom: 50px;
}

.itemDate,
.itemDateRight {
  font-size: 25px;
  padding-bottom: 5px;
}

.itemDesc {
  position: relative;
  &::after {
    position: absolute;
    right: -10px;
    top: -10px;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #cc2229;
  }
}

.itemDescRight {
  position: relative;
  &::after {
    position: absolute;
    left: -10px;
    top: -10px;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #cc2229;
  }
}

.line {
  height: 2px;
  background-color: white;
}

.itemDesc,
.itemDescRight {
  padding-top: 10px;
  font-size: 20px;
}

.item {
  width: 50%;
  border-right: 2px solid white;
}

.itemRight {
  margin-left: calc(50% - 2px);
  border-left: 2px solid white;
  width: 50%;
}

.itemDateRight,
.itemDescRight {
  padding-left: 50px;
}

.startDate {
  width: 50%;
  margin-left: calc(50% - 2px);
  border-left: 2px solid white;
  position: relative;
  p {
    position: relative;
    z-index: 1;
    top: -20px;
    padding: 5px 10px;
    margin-left: auto;
    width: max-content;
    font-size: 25px;
    color: #004669;
    background-color: white;
  }

  &::before {
    z-index: 1;
    position: absolute;
    left: -6px;
    top: -5px;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: white;
  }

  &::after {
    position: absolute;
    left: 0px;
    top: 0px;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: url('../../../../images/MasterPlan/dashed-line.svg') center;
    background-size: cover;
  }
}

.endDate {
  width: 50%;
  border-right: 2px solid white;
  position: relative;
  p {
    position: relative;
    z-index: 1;
    bottom: -20px;
    padding: 5px 10px;
    width: max-content;
    font-size: 25px;
    color: #004669;
    background-color: white;
  }

  &::before {
    z-index: 1;
    position: absolute;
    right: -6px;
    bottom: -5px;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: white;
  }

  &::after {
    position: absolute;
    right: 0px;
    bottom: 0px;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: url('../../../../images/MasterPlan/dashed-line.svg') center;
    background-size: cover;
  }
}

.endDateRight {
  width: 50%;
  margin-left: calc(50% - 2px);
  border-left: 2px solid white;
  position: relative;
  p {
    position: relative;
    z-index: 1;
    bottom: -20px;
    padding: 5px 10px;
    margin-left: auto;
    width: max-content;
    font-size: 25px;
    color: #004669;
    background-color: white;
  }

  &::before {
    z-index: 1;
    position: absolute;
    left: -6px;
    bottom: -5px;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: white;
  }

  &::after {
    position: absolute;
    left: 0px;
    bottom: 0px;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: url('../../../../images/MasterPlan/dashed-line.svg') center;
    background-size: cover;
  }
}

@media (max-width: 1000px) {
  .startDate,
  .endDate {
    p {
      font-size: 20px;
    }
  }
  .itemDate,
  .itemDateRight,
  .itemDesc,
  .itemDescRight {
    font-size: 16px;
  }
  .itemDateRight,
  .itemDescRight {
    padding-left: 25px;
  }
  .stage {
    font-size: 20px;
  }
  .stages {
    flex-direction: column;
    // width: 90%;
    gap: 50px;
  }
  .numberWrapper {
    gap: 50px;
    align-items: flex-start;
    flex-direction: row;
  }
  .stageTitle {
    font-size: 20px;
    text-align: left;
  }
}

@media (max-width: 500px) {
  .stage {
    font-size: 18px;
  }
  .numberWrapper {
    gap: 20px;
  }
  .stageTitle {
    font-size: 18px;
  }
}
