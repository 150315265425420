.header {
  position: fixed;
  top: 0;
  right: 50px;
  // width: 100%;
  left: 0;

  box-sizing: border-box;
  transition: background-color 0.2s ease;
  z-index: 200;

  @media (max-width: 768px) {
    // background-color: #fff;
    // height: 100px;

    .after {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 95px;
      background-color: white;
      z-index: 1000;
    }
  }

  &.open {
    background-color: #00405b !important;
    bottom: 0;
    z-index: 500;

    color: #ffffff;

    &::after {
      display: none;
    }
  }
}

.notFixed {
  position: static;
}
