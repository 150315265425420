.back {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.5;
  color: #00405b;

  position: relative;
  // padding-left: 76px;

  width: max-content;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    padding-left: 30px;
  }

  svg {
    position: relative;
    right: 5px;

    transition: 0.3s;

    width: 20px;
    height: 20px;
  }

  p {
    // width: min-content;
  }

  &:hover {
    svg {
      right: 15px;
    }
  }

  // &:hover {
  //   &::after {
  //     left: -30px;

  //     @media (max-width: 768px) {
  //       left: 35%;
  //     }
  //   }
  // }

  // &::before {
  //   content: attr(data-text);
  //   position: absolute;
  //   left: 0;
  //   top: 0;

  //   @media (max-width: 768px) {
  //     content: "";
  //     width: 42px;
  //     height: 42px;
  //     background-color: #00405b;
  //   }
  // }

  // &::after {
  //   content: url("data:image/svg+xml,%3Csvg width='11' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.7 1 1.4 9.3l8.3 8.3' stroke='%23FF2E17' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  //   position: absolute;
  //   left: -20px;
  //   top: 1px;
  //   transition: all 0.3s ease;

  //   @media (max-width: 768px) {
  //     content: url("data:image/svg+xml,%3Csvg width='20' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10.3 1.2 8.4 12H1.5l8.8-12Z' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  //     left: 35%;
  //     top: 3px;
  //   }
  // }

  // &.down {
  //   &::after {
  //     @media (max-width: 768px) {
  //       transform: rotate(-90deg);
  //     }
  //   }
  // }
}

.backRight {
  svg {
    transform: rotate(180deg);
    right: -100%;
  }

  &:hover {
    svg {
      right: -105%;
    }
  }
}

.mobileBackButton {
  display: flex;
  align-items: center;
  justify-content: end;

  // width: 100vw;
  height: 45px;
  // position: absolute;
  // right: 30px;
  // top: -7px;
  z-index: 100;

  .back {
    width: 42px;
    height: 42px;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.5;
    color: #00405b;

    position: relative;
    // padding-left: 100px;

    background-color: #00405b;

    @media (max-width: 768px) {
      padding-left: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;

        transition: 0.3s;

        width: 20px;
        height: 20px;
      }
    }

    // &:hover {
    //   &::after {
    //     // left: 30px;

    //     @media (max-width: 768px) {
    //       // left: 3%;
    //     }
    //   }
    // }

    // &::before {
    //   content: attr(data-text);
    //   position: absolute;
    //   right: 100px;
    //   top: 0;

    //   @media (max-width: 768px) {
    //     content: "";
    //     width: 42px;
    //     height: 42px;
    //     background-color: #00405b;
    //   }
    // }

    // &::after {
    //   content: url("data:image/svg+xml,%3Csvg width='11' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.7 1 1.4 9.3l8.3 8.3' stroke='%23FF2E17' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    //   position: absolute;
    //   left: -20px;
    //   top: 1px;
    //   transition: all 0.3s ease;

    //   @media (max-width: 768px) {
    //     content: url("data:image/svg+xml,%3Csvg width='20' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10.3 1.2 8.4 12H1.5l8.8-12Z' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    //     left: 27%;
    //     top: 3px;
    //   }
    // }

    &.down {
      &::after {
        @media (max-width: 768px) {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
