.aside {
  min-height: 270px;
  h3 {
    font-size: 24px;
    line-height: 1.1;
    font-weight: 600;
    text-transform: uppercase;

    margin-bottom: 15px;
  }
}

.name {
  font-size: 20px;
  line-height: 1.13;
  font-weight: 600;

  margin-bottom: 20px;
}

.tel {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  color: #92a7b1;

  display: block;
  margin-bottom: 0px;
  user-select: all;
}

.email {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  color: #92a7b1;

  margin-bottom: 0px;
}

.link {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  color: #92a7b1;
}
