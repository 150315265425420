.map {
  position: relative;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
  p {
    font-size: 25px;
    line-height: 1.13;
    text-align: right;
    color: #92a7b1;

    margin-top: 15px;

    @media (max-width: 1000px) {
      text-align: center;
      font-size: 15px;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  &::after {
    content: url("../../../../../images/logo-blue.svg");
    position: absolute;
    left: -131px;
    bottom: -90px;
    width: 250px;
    height: auto;

    @media (max-width: 1000px) {
      width: 128px;
      left: -67px;
      bottom: -70px;
    }
  }
}
