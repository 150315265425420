.vacancy {
  h2 {
    font-size: 35px;
    line-height: 1.13;
    font-weight: 600;

    position: relative;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 19px;
      margin-bottom: 25px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #00405b;

      @media (max-width: 768px) {
        bottom: -8px;
      }
    }
  }
}

.list {
  padding-left: 20px;

  li {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 1.13;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-70%);
      width: 7px;
      height: 7px;
      background-color: #ff2e17;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 6px;
        height: 6px;
        left: -15px;
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 1.13;
    margin-bottom: 7px;
    position: relative;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: -13px;
      top: 8px;
      width: 7px;
      height: 1px;
      background-color: #00405b;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
