.item {
  width: auto;
  // margin-left: 40px;
  padding: 0 20px;
  position: relative;

  border-left: 1px solid white;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: -20px;
  //   transform: translateY(-50%);
  //   width: 1px;
  //   height: 100%;
  //   background-color: #00405b;
  // }

  &:first-child {
    // &::after {
    //   display: none;
    // }
    border-left: none;
  }

  &:last-child {
    // &::after {
    //   display: none;
    // }
    padding-right: 0;
    @media (max-width: 1000px) {
      padding-right: 20px;
    }
  }

  a {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #00405b;
    display: block;
    width: 100%;
    display: flex;

    justify-content: center;

    transition: transform 0.3s ease;
    backface-visibility: hidden;

    &:hover {
      transform: scale(1.2);
    }

    &.pressed {
      transition: 0.2s;
      color: #ff2e17 !important;
      transform: scale(1.2);
    }
  }

  &.open {
    // background-color: red;
    border-left: none;
    // margin-bottom: 0;

    a {
      text-transform: none;

      &.pressed {
        position: relative;

        span {
          color: white;
          transform: none;
        }

        &::after {
          content: '';
          position: absolute;
          width: calc(7px + 1vw);
          height: calc(7px + 1vw);
          background-color: #ff2e17;
          left: calc(-20px - 2vw);
          top: 45%;
          transform: translateY(-50%);
          border-radius: 50%;
        }
      }
    }
  }
}
