.slider {
  width: 100%;
  height: 420px;
  // background-color: lightblue;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0 auto;
    // height: 300px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    width: 100%;
    // height: 300px;
    margin: 30px auto 40px;
  }
}

.imageWrappContainer {
  position: relative;
  // .decor {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 32px;
  //   background: linear-gradient(360deg, #FFFFFF -43.69%, rgba(255, 255, 255, 0) 100%);
  // }
}

.desktopImageWrap {
  max-height: 414px;
  overflow-y: auto;
  

  .img {
    width: 100%;
    height: 367px;
    margin-bottom: 15px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
