.wrapper {
  margin-top: 100px;
  padding: 0 5%;
  display: flex;
  gap: 10%;
}

.button {
  height: max-content;
  width: 30%;
  text-align: center;
  padding: 10px 10px 15px;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: solomon;
  background-color: #004669;
}

.left {
  width: 30%;
  position: relative;

  @media (min-width: 1000px) {
    max-height: 415px !important;
    overflow-y: auto;
    padding-right: 57px;
  }

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: #00405b;
    border-left: 6px solid #ffffff; /* ширина всей полосы прокрутки */
    border-right: 6px solid #ffffff; /* ширина всей полосы прокрутки */
    /* цвет зоны отслеживания */
  }

  &::-webkit-scrollbar-thumb {
    // height: 14px;

    background-color: #92a7b1; /* цвет бегунка */ /* округлось бегунка */
    // border-top: 38px solid red;  /* отступ вокруг бегунка */
    // border-bottom: 38px solid red;  /* отступ вокруг бегунка */
  }
}

.idea {
  display: flex;
  align-items: center;
  gap: 50px;
  cursor: pointer;

  padding: 10px 23px;

  font-family: solomon;

  background-color: transparent;

  p {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    color: #00405b;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  img {
    width: 63px;
    height: 63px;
    object-fit: cover;
  }

  &:hover {
    background-color: #eff2f3;
  }
}

.mapWrapper {
  width: 70%;
}

.map {
  // &::after {
  //   content: ""
  position: relative;
  z-index: 0;
  padding-right: 40px;

  @media (max-width: 1000px) {
    padding-right: 0;
  }

  #map {
    border: 2px solid #00405b !important;
    width: 100%;
  }

  // }
  img.decorImg {
    position: absolute;
    // display: inline;
    width: auto;

    right: -29px;
    bottom: -69px;

    @media (max-width: 1000px) {
      display: none;
    }
  }
}

@media (max-width: 1400px) {
  .left {
    width: 45%;
  }

  .mapWrapper {
    width: 50%;
  }
  .wrapper {
    gap: 2%;
  }
}

@media (max-width: 1000px) {
  .left {
    margin-top: 50px;
    width: 50%;
  }
  .mapWrapper {
    width: 100%;
  }
  .wrapper {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .left {
    width: 100%;
  }
}
