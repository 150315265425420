.leaflet-popup-content-wrapper {
    opacity: 1 !important;
    background-color: #ffffff !important; 
    border-radius: 0 !important;

    color: #00405B !important;
    font-size: 18px !important;
    font-weight: 600 !important;

    width: auto !important;

    white-space: nowrap !important;
    // text-align: center !important;

    font-family: solomon !important;

    a {
        color: #00405B !important;
        display: block !important;
    }

    p {
        margin-bottom: 4px !important;
        margin-top: 0 !important;
        text-align: center !important;
        color: #00405B !important;

    }

    span {
        font-weight: 400 !important;
        font-size: 15px !important;

        color: #92A7B1 !important;
        text-align: center !important;
    }
}

@keyframes showActiveMarker {
    from {
        transform: scale(0) !important;
    } 
    to {
        transform: scale(1) !important;
    }
}

.leaflet-popup_show_true {
    position: relative !important;
    transition: 3s !important;

    &::after {
        content: "" !important;
        position: absolute !important;
        animation-name: showActiveMarker !important;
        animation-duration: .1s !important;
        bottom: -48px !important;
        display: block !important;
        width: 37px !important;
        height: 42px !important;
        background-image: url('../../../../images/map_marker_active.svg') !important;
        left: calc(50% - 19px) !important;
    }
}



.leaflet-popup-close-button {
    display: none !important;
}

.leaflet-map-pane .leaflet-popup-tip-container_svg {
    display: none !important;
    // display: block;
    // content: "";
}

.dg-popup_hidden_true {
    // display: block !important;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    // transform: translate3d(266px, 142px, 0px) !important;
    margin-bottom: -10px !important;
    width: auto !important;
}

.leaflet-popup-content {
    margin: 0 !important;
    padding: 7px 13px 4px 13px !important;
}


#map.default {
    .leaflet-popup-content-wrapper  {
        p {
            margin-bottom: 4px !important;
            margin-top: 0 !important;
            // text-align: center !important;
            color: #00405B !important;

            font-size: 16px;
            white-space: pre-wrap;
    
        }
    
        span {
            font-weight: 400 !important;
            font-size: 15px !important;
    
            color: #92A7B1 !important;
            text-align: center !important;
        }
    }
}