.item {
  display: flex;

  // padding-top: 25px;
  // padding-bottom: 25px;

  border-bottom: 2px solid #92a7b1;
  position: relative;

  @media (max-width: 768px) {
    border: 1px solid #00405b;

    margin-bottom: 30px;

    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: calc(40% - 2px);
  //   width: 2px;
  //   height: 100%;
  //   background-color: #92a7b1;

  //   @media (max-width: 768px) {
  //     display: none;
  //   }
  // }
}

.type {
  // width: 40%;
  flex: 0 0 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 25px 15px;

  font-weight: 400;
  font-size: 18px;
  line-height: 1.13;

  position: relative;

  border-right: 2px solid #92a7b1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 15px;
    text-align: left;
  }

  &::after {
    content: '';
    display: none;
    position: absolute;

    bottom: 0;
    left: 0;
    background-color: #00405b;
    width: 100%;
    height: 1px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  p {
    width: auto;
    @media (max-width: 1200px) {
      font-size: 14px;
      text-align: left;
    }
    @media (max-width: 768px) {
      font-weight: 600;
    }
  }
}

.name {
  flex: 0 0 40%;

  padding: 25px 15px;
  border-right: 2px solid #92a7b1;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px;
    padding-bottom: 0;
  }

  p {
    // margin-bottom: 15px;

    font-weight: 600;
    font-size: 18px;
    line-height: 1.13;
    @media (max-width: 1200px) {
      font-size: 14px;
      text-align: left;
    }
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
}

.documents {
  flex: 0 0 40%;
  overflow: hidden;
  padding: 25px 15px;
  @media (max-width: 768px) {
    padding: 20px 20px;
    padding-top: 0;
  }
  a {
    margin-left: 20px;
    margin-bottom: 18px;
    position: relative;
    width: auto;

    display: inline-block;

    font-weight: 400;
    font-size: 18px;
    line-height: 113.5%;
    color: #00405b;

    text-decoration: underline;

    @media (max-width: 1200px) {
      font-size: 14px;
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 6px;

      // transform: translateY(-65%);

      width: 7px;
      height: 7px;
      background-color: #ff2e17;
      border-radius: 50%;
    }

    // &::after {
    //   content: "";
    //   position: absolute;

    //   height: 1px;
    //   width: 100%;
    //   bottom: -8px;
    //   left: 0;
    //   background-color: #92a7b1;
    // }
  }
}
