.secondaryInfo {
  position: relative;
  background-color: #eff2f4;
  border-top: 2px solid #00405b;
  border-bottom: 2px solid #00405b;
  padding: 20px 37px;
  // z-index: 2;

  display: flex;
  flex-wrap: wrap;

  margin-bottom: -2px;

  @media (max-width: 768px) {
    border-width: 1px;
    padding: 15px;
    flex-direction: column;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

.organization {
  flex: 1 0 100%;

  margin-bottom: 36px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 22px;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 17px;
    }
  }

  p {
    white-space: pre-wrap;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.objects {
  flex: 1 0 50%;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 10px;
  }

  a {
    margin-left: 15px;
    position: relative;
    width: auto;

    font-size: 18px;
    font-weight: 500;
    color: #00405b;
    text-decoration: underline;
    line-height: 1.13;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    &::before {
      content: "";
      position: absolute;
      left: -15px;
      top: 5px;
      width: 6px;
      height: 6px;
      background-color: #ff2e17;
      border-radius: 50%;
    }
  }
}

.docum {
  flex: 1 0 50%;

  .links {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }

    a {
      display: inline;
      position: relative;
      width: auto;

      font-size: 18px;
      font-weight: 500;
      color: #00405b;
      text-decoration: underline;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}
