.aside {
    position: relative;
    ul {
        
        @media (min-width: 1000px) {
            max-height: 415px !important;
            overflow-y: auto;
            width: 440px;
        padding-right: 57px;
        }

        

        &::-webkit-scrollbar {
            width: 14px;
          }
          
          &::-webkit-scrollbar-track {
            background: #00405B;   
            border-left: 6px solid #ffffff;               /* ширина всей полосы прокрутки */
            border-right: 6px solid #ffffff;               /* ширина всей полосы прокрутки */
                 /* цвет зоны отслеживания */
          }
          
          &::-webkit-scrollbar-thumb {
            // height: 14px;

            background-color: #92A7B1;    /* цвет бегунка */     /* округлось бегунка */
            // border-top: 38px solid red;  /* отступ вокруг бегунка */
            // border-bottom: 38px solid red;  /* отступ вокруг бегунка */
          }
    }

    // .scrollbar {
    //     position: absolute;
    //     right: -51px;
    //     top: 0;
    //     bottom: 0;

    //     height: calc(100% - 40px);
    //     width: 2px;

    //     background-color: #00405B;

    //     &::after {
    //         content: '';
    //         position: absolute;
    //         bottom: -40px;
    //         display: block;
    //         height: 40px;
    //         width: 2px;
    //         background-color: #00405B;

    //         z-index: 0;
    //     }

    //     .scrollbarCarete {
    //         // cursor: pointer;
    //         position: absolute;
    //         left: -6px;
    //         width: 14px;
    //         height: 40px;
    //         background-color: #92A7B1;
    //         z-index: 1;
    //     }
    // }
}
