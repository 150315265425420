.year {
  display: flex;
  flex-direction: column;
  position: relative;

  h3 {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.13;

    margin-bottom: 16px;

    text-align: center;
  }

  .list {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1000px) and (max-width: 1290px) {
      margin-top: 55px !important;
      padding-bottom: 50px !important;
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }

    // text-align: center;
  }

  label {
    width: 70px;
    // flex: 0 1 50%;
    margin-bottom: 6px;
    cursor: pointer;

    // &:nth-last-of-type(2n-1) {
    //   display: flex;
    //   justify-content: flex-end;
    // }
  }

  .mark {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #e5e3e6;
    transition: all 0.15s ease;
  }

  input[type="checkbox"]:checked {
    & + .mark {
      background-color: #ff2e17;
      // outline: 1px solid red;
    }
  }

  .name {
    display: inline-block;
    font-size: 17px;
    line-height: 1.13;
    width: auto;
    margin-left: 11px;
    user-select: none;
  }

  button {
    display: inline-block;
    flex: 0 1 auto;
    // justify-self: center;
    align-self: center;
    text-align: center;

    font-family: solomon;
    font-size: 16px;
    line-height: 1.13;
    position: relative;
    color: #00405b;

    width: 80px;
    background-color: transparent;

    margin-top: 15px;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;

    text-decoration: underline;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;
    //   left: 0;
    //   bottom: 0;
    //   background-color: #00405b;
    // }
  }

  &.modal {
    label {
      margin-bottom: 16px;
    }

    .mark {
      width: 14px;
      height: 14px;
    }

    button {
      display: none;
    }
  }
}
