.item {
  border-top: 2px solid #00405b;
  padding: 20px 35px;
  display: flex;
  flex-wrap: wrap;
  background-color: #eff2f4;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
  }
}

.company {
  flex: 1 0 100%;

  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 20px;
  margin-bottom: 26px;

  @media (max-width: 768px) {
    display: block;
    // grid-template-columns: 1fr;
    // column-gap: unset;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  a {
    text-align: right;
    color: #92a7b1;

    @media (max-width: 768px) {
      margin-bottom: 10px;
      display: block;
      text-align: left;
    }
  }

  p {
    grid-area: 2/1/3/3;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.objects {
  flex: 1 0 50%;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 15px;
  }

  h4 {
    margin-bottom: 15px;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 7px;
  }

  a {
    margin-left: 20px;
    position: relative;

    font-size: 18px;
    color: #00405b;
    line-height: 1.3;
    text-decoration: underline;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      left: -20px;
      top: 5px;
      background-color: #ff2e17;
      border-radius: 50%;

      @media (max-width: 768px) {
        top: 3px;
      }
    }
  }
}

.docum {
  flex: 1 0 50%;

  h4 {
    margin-bottom: 15px;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 7px;
  }

  a {
    position: relative;

    font-size: 18px;
    color: #00405b;
    text-decoration: underline;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
