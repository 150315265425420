.logo {
  display: none;
  position: absolute;
  bottom: -70px;
  right: calc(40vw - 20px);

  img {
    display: block;
    width: 110px;
    height: 110px;
  }

  &.open {
    display: block;
  }
}
