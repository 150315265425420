.content {
  
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 50px;
  }
}

.pageTitle {
  font-size: 21px;
  font-weight: 600px;
}
