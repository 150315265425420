.modalWrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  backdrop-filter: blur(5px);

  z-index: 1001;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }
  .prev,
  .next {
    width: 30px;
    height: 30px;

    position: absolute;
    top: calc(50% - 15px);

    cursor: pointer;
  }
  .next {
    right: 30px;
    @media (max-width: 768px) {
      right: 5px;
    }
  }
  .prev {
    transform: rotate(180deg);
    left: 30px;

    @media (max-width: 768px) {
      left: 5px;
    }
  }

  .close {
    width: max-content;
    position: absolute;
    top: 100px;
    right: 50px;

    cursor: pointer;
  }

  .photo {
    // width: 70vw;
    // max-height: 100%;
    max-width: 70vw;
    max-height: 70vh;

    height: auto;
    width: auto;
    // object-fit: cover;

    // transition: 0.2s;
  }
}
