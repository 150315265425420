.logo {
  display: inline;
  width: auto;

  position: absolute;
  top: 30px;
  left: 7vw;

  @media (max-width: 1150px) {
    top: 50px;
    left: 5vw;
  }

  @media (max-width: 1200px) {
    display: none;
  }

  // outline: 1px solid green;

  img {
    display: block;
    // outline: 1px solid blue;
    // width: auto;
    width: 80%;
    height: auto;

    @media (max-width: 1250px) {
      width: 150px;
    }
  }

  &.open {
    // display: none;
    top: 40px;
    display: block;
    width: 250px;
    height: 38px;

    @media (max-width: 500px) {
      width: 200px;
      height: 30px;
    }

    img {
      width: 100%;
    }
  }
}
