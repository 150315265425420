.activities {
  margin-bottom: 100px;

  h2 {
    font-size: 30px;
    line-height: 1.13;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 19px;
      margin-bottom: 40px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.13;
    margin-bottom: 13px;
    position: relative;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 7px;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-70%);
      width: 7px;
      height: 7px;
      background-color: #ff2e17;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 5px;
        height: 5px;
        left: -14px;
      }
    }
  }

  p {
    font-size: 20px;
    line-height: 1.13;
    // text-indent: 30px;
    margin-bottom: 5px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  margin-bottom: 70px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: calc(250px - 10vw);
  row-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  li {
    padding-left: 20px;
  }
}
