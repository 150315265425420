.info {
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    padding-bottom: 200px;
  }
}

.description {
  margin-bottom: 50px;

  span {
    display: block;
    font-size: 18px;

    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.links {
  @media (max-width: 768px) {
    order: -1;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

.link {
  margin-bottom: 15px;
  a {
    display: inline;
    font-size: 18px;
    font-weight: 600;
    color: #00405b;

    width: auto;

    margin-bottom: 23px;
    position: relative;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    span {
      padding-bottom: 5px;
    }
    // outline: 1px solid red;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: -5px;
    //   width: 100%;
    //   height: 1px;
    //   background-color: #00405b;
    // }

    text-decoration: underline;
    text-decoration-color: #00405b;
  }
}
