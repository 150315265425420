.footer {
  flex: 0 1 auto;
  position: relative;

  padding: 22px 40px;

  h2 {
    font-size: 24px;
    line-height: 150%;
    font-weight: 500;
    color: #00405b;
    position: relative;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: -40px;

      width: 150%;
      height: 1px;
      background-color: #ff2e17;
    }

    @media (max-width: 768px) {
      font-size: 15px;
      font-weight: 400;
    }
  }

  .address,
  .mail,
  .tel {
    font-size: 17px;
    line-height: 150%;
    font-style: normal;
    color: #92a7b1;
    position: relative;
    padding-left: 30px;
    display: inline-block;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      font-size: 14px;
      font-weight: 400;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 50%;

      @media (max-width: 1000px) {
        top: 14px;
      }
    }
  }

  .address {
    &::before {
      content: url("../../../images/icon-baloon.svg");
      width: 12px;
      height: 17px;
      transform: translateY(-60%);
    }
  }
  .tel {
    &::before {
      content: url("../../../images/icon-phone.svg");
      width: 18px;
      height: 17px;
      transform: translateY(-60%);
    }
  }
  .mail {
    &::before {
      content: url("../../../images/icon-mail.svg");
      width: 18px;
      height: 11px;
      transform: translateY(-110%);

      @media (max-width: 1000px) {
        // top: 14px;
        transform: translateY(-120%);
      }
    }
  }
}

.links {
  display: flex;
  width: 80px;
  justify-content: space-between;
  position: absolute;
  // display: inline;
  bottom: 22px;
  right: 40px;

  height: 32px;
  a {
    display: block;
    width: 32px;
  }
  img {
    display: inline-block;
    width: 32px;
    height: 32px;

  }
}

.vk {
  
}
