.search {
  display: flex;
  justify-content: flex-end;

  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.input {
  // display: inline;
  width: 100%;
  height: auto;
  border: 2px solid #00405b;
  padding: 13px 22px;

  font-weight: 400;
  font-size: 18px;
  color: #00405b;

  @media (max-width: 1000px) {
    min-width: 100%;
  }

  @media (max-width: 768px) {
    // width: 30vw;
    font-size: 14px;
    padding: 7px 13px;
    border: 1px solid #00405b;

    margin-bottom: 20px;
    // outline: 1px solid #00405b;
    width: 100%;
  }

  // box-sizing: content-box;

  &::placeholder {
    color: #92a7b1;
  }

  &:focus {
    border-color: #00405b;

    @media (max-width: 768px) {
      border: 1px solid #00405b;
    }
  }
}
