.item {
  border-bottom: 2px solid #92a7b1;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    padding-top: 0;
    margin-bottom: 30px;
    border: 1px solid #00405b;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    border-bottom: none;
    @media (max-width: 768px) {
      border-bottom: 1px solid #00405b;
    }
  }
}
