.aside {
  min-height: 190px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
  h3 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;

    margin-bottom: 14px;

    @media (min-width: 768px) {
      font-size: 45px;
    }

    @media (min-width: 768px) and (max-width: 1000px) {
      font-size: 35px;
    }

    &::after {
      content: "";
      display: block;
      width: 90px;
      height: 1px;
      background-color: red;

      margin-top: 5px;
      @media (min-width: 768px) {
        width: 180px;
      }
    }
  }
}

.name {
  font-size: 15px;
  line-height: 1.13;
  font-weight: 600;

  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 1000px) {
    font-size: 30px;
  }
}

.tel {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: #92a7b1;

  display: block;
  user-select: all;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 1000px) {
    font-size: 30px;
  }
}

.email {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: #92a7b1;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 1000px) {
    font-size: 30px;
  }
}

.link {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: #92a7b1;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 1000px) {
    font-size: 30px;
  }
}
