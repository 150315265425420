.aside {
  display: flex;
  flex-direction: column;

  width: 100%;
  // max-width: 250px;
  // margin-right: 55px;
  // background-color: pink;
  // position: relative;

  // overflow-y: auto;

  @media (max-width: 768px) {
    width: 100% !important;
  }
  .title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
  
    @media (max-width: 1000px) {
      position: relative;
      width: auto;
      height: auto;
      margin: unset;
      clip: unset;
      overflow: auto;
      color: #00405b;
      font-size: 21px;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}
