html {
  overflow-x: hidden;
  height: 100%;
}

body {
  font-family: solomon;
  font-size: 16px;
  color: #00405b;
  margin: 0 auto;
  min-width: 320px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
