.map {
  // &::after {
  //   content: ""
  position: relative;
  z-index: 0;
  padding-right: 40px;
  margin-top: 30px;

  @media (max-width: 1000px) {
    padding-right: 0;
  }

  #map {
    border: 2px solid #00405b !important;
    width: 100%;
  }

  // }
  img.decorImg {
    position: absolute;
    // display: inline;
    width: auto;

    right: -29px;
    bottom: -69px;

    @media (max-width: 1000px) {
      display: none;
    }
  }
}
