.item {
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.button {
  display: grid;
  grid-template-columns: 1fr 60px;
  column-gap: 15px;
  text-align: left;

  // width: 100%;
  // height: 100%;

  padding: 10px 23px;

  font-family: solomon;

  background-color: transparent;

  @media (max-width: 768px) {
    padding: 8px 13px;
  }

  &:hover {
    background-color: #eff2f3;
  }
  &:active {
    background-color: #e6e8ea;
  }

  &.pressed {
    background-color: #eff2f3;
  }
}

.title {
  grid-area: 1/1/2/2;

  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  color: #00405b;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.street {
  grid-area: 2/1/3/2;

  font-size: 15px;
  line-height: 1.3;
  font-weight: 500;
  color: #92a7b1;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.status {
  grid-area: 3/1/4/2;

  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  color: #00405b;



  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.image {
  grid-area: 1/2/4/3;
  justify-self: center;
  align-self: center;

  display: block;
  width: 63px;
  height: 63px;
  object-fit: cover;

  @media (max-width: 1000px) {
    width: 60px;
    height: 60px;
  }
}
