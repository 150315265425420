.head {
  // display: grid;
  // grid-template-columns: repeat(9, 1fr);
  // grid-template-rows: 1fr;
  // grid-column-gap: 0px;
  // grid-row-gap: 0px;
  display: flex;
  justify-content: space-between; 
  padding-top: 10px;

  

  @media (max-width: 1150px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }


  .title {
    // margin-right: auto;
    width: auto;
    // grid-area: 1 / 1 / 2 / 6;
    padding-right: 20px;  
  
    font-size: 25px;
    font-weight: 600;
    line-height: 1.13;

    // @media (min-width: 1000px) {
    //   min-height: 86px;
    // }
  
    @media (max-width: 1150px) {
      margin-bottom: 20px;
    }
  
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    width: auto;

    @media (max-width: 768px) {
      width: 100%;
      
    }


    .date {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: #949494;
    
      // grid-area: 1 / 6 / 2 / 8;
    
      display: flex;
      justify-content: flex-end; 
      
      // margin-left: 25px;
      @media (max-width: 1150px) {
        margin-left: 0;
      }
    
      @media (max-width: 768px) {
        font-size: 15px;
        order: 5;
      }
    }
    .video {
      display: flex;
      justify-content: flex-end;
      text-align: center;
    
      color: #00405b;
      font-size: 18px;
      line-height: 1.5;
      position: relative;

      white-space: nowrap;

      padding-left: 35px; 
      margin-left: 20px;
    
      // margin-left: 60px;
    
      // grid-area: 1 / 8 / 2 / 10;
    
      @media (max-width: 1150px) {
        margin-left: 0;
      }
    
      @media (max-width: 768px) {
        font-size: 15px;
      }
    
      &:hover {
        &::before {
          transform: translateY(-50%) rotate(120deg);
        }
      }
    
      &::before {
        content: url("data:image/svg+xml,%3Csvg width='31' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.3' cy='15.2' r='14.8' fill='%2392A7B1'/%3E%3Cpath d='M24.3 15.2 10 23.5V7l14.3 8.2Z' fill='%23fff'/%3E%3C/svg%3E");
        position: absolute;
        left: 0px;
        top: 12px;
    
        width: 30px;
        height: 30px;
    
        transform: translateY(-50%);
    
        transition: transform 0.3s ease;
        @media (max-width: 768px) {
          left: 0px;
          top: 36%;
        }
      }
    }
  }
}




