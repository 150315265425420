.purch {
    position: relative;
    @media (min-width: 1000px) {
        display: flex;
        flex-direction: column;
    }
}

.decorWrapper {
    align-self: end;
    display: flex;
    width: 50%;
    justify-content: end;
    position: absolute;
    bottom: 0;
    right: 0;

    z-index: 1;

    @media (max-width: 1000px) {
        justify-content: start;
        margin-top: -450px;
        margin-bottom: 0px;
        position: relative;
        width: 48%;

        align-self: start
    }

    @media (max-width: 767px) {
        justify-content: start;
        margin-top: -180px;
        margin-bottom: 0px;
        position: relative;
        width: 100%;
    }
    svg {
        width: 70%;
        max-width: 570px;
        height: auto;
        max-height: 361px;

        @media (max-width: 1000px) {
            width: 100%;
            max-width: 1000px;
            max-height: 500px;
            // width: 215px;
            // height: 270px;
        }

        @media (max-width: 767px) {
            width: 215px;
            height: 270px;

            @media (max-height: 741px) {
            height: 400px;
                
            }
        }


    }

    
}