.wrapper {
  color: #004669;
  // width: 90%;
  width: 80%;
  margin: 0 auto;
  font-size: 20px;
}

.cards {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.card {
  position: relative;

  img {
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 30px;
  }
}

.button {
  width: 70%;
  text-align: center;
  text-wrap: balance;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 10px 15px;
  border-radius: 30px;
  bottom: 5%;
  left: 15%;
  position: absolute;
  background: linear-gradient(
    180deg,
    #fff 25%,
    #fff 90%,
    rgba(255, 255, 255, 0) 100%
  );
}

.text {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;

  p {
    margin-bottom: 10px;
  }
}

.news {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-bottom: 50px;
}

.new {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.newImage {
  aspect-ratio: 2/1;
  object-fit: cover;
}

.newDate {
  margin-top: 20px;
}

.newTitle {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 1450px) {
  .wrapper {
    font-size: 16px;
  }
  .button {
    font-size: 18px;
  }
  .newTitle {
    font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .cards,
  .news {
    grid-template-columns: repeat(2, 1fr);
  }
  .button {
    font-size: 16px;
  }
  .newTitle {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .cards,
  .news {
    grid-template-columns: repeat(1, 1fr);
  }
}
