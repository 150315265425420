@font-face {
  font-family: solomon;
  font-display: swap;
  src: url("../../fonts/SolomonSans-Regular.woff2") format("woff2"),
    url("../../fonts/SolomonSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: solomon;
  font-display: swap;
  src: url("../../fonts/SolomonSans-SemiBold.woff2") format("woff2"),
    url("../../fonts/SolomonSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: solomon;
  font-display: swap;
  src: url("../../fonts/SolomonSans-Medium.woff2") format("woff2"),
    url("../../fonts/SolomonSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
