.title {
  background-color: #004669;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 5%;
  padding-bottom: 5px;
}

.result {
  // width: 90%;
  width: 80%;
  margin: 0 auto;
}

.slider {
  width: 50%;
  margin: 0 auto;
}

.link {
  font-size: 20px;
}
