// .aside {
//   @media (max-width: 1000px) {
//     width: 280px;
//   }
// }

.purchasesRegistry {
}

.list {
  z-index: 100;
  @media (max-width: 1000px) {
    margin-bottom: 27px;
  }
}

.item {
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 17px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  background-color: #fff;
  border: 2px solid #00405b;
  padding: 18px 15px;

  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 1.13;
  color: #00405b;
  white-space: wrap;

  margin-bottom: 0px;
  transition: all 0.3s ease;

  text-align: center;

  @media (max-width: 768px) {
    border-width: 1px;
  }

  &:hover {
    color: #ffffff;
    background-color: #00405b;
  }

  &.pressed {
    color: #ffffff;
    background-color: #00405b;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-bottom: 12px;
    }

    & + .filterbox {
      transform: scale(1);
      height: auto;
    }
  }

  &:active {
    color: #ffffff90;
  }
}
.filterbox {
  transform: scale(0);
  height: 0;

  @media (max-width: 768px) {
    padding-left: 5px;
  }
  // margin-bottom: 0;
}

.filteritem {
  padding: 5px;
  @media (max-width: 768px) {
    // padding: 5px;
    label {
      display: flex;
      align-items: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 6px;
    cursor: pointer;

    display: flex;
    flex-wrap: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mark {
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    background-color: #e5e3e6;
    transition: all 0.15s ease;
    border-radius: 50%;
  }

  input[type="radio"]:checked {
    & + .mark {
      background-color: #ff2e17;
      // outline: 1px solid red;
    }
  }

  .name {
    display: inline-block;

    font-size: 17px;
    font-weight: 500;
    line-height: 1.13;
    width: auto;
    margin-left: 12px;
    user-select: none;
  }
  
  .mark_check {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #e5e3e6;
    transition: all 0.15s ease;
    transform: translate(4px, 5px);
    // border-radius: 50%;
    color: #ff2e17;
  }

  input[type="checkbox"]:checked {
    & + .mark_check {
      background-color: #ff2e17;
      
    }
  }
}

.submenu {
  padding-left: 10px;
}

