.docs {
  background-color: white;
  display: flex;
  flex-direction: column;
  // padding: 0 5%;
  padding: 0 10%;
  margin-bottom: 50px;
}

.doc {
  width: max-content;
  background: none;
  color: #004669;
  font-size: 24px;
  font-weight: 700;
  text-decoration: underline;
}

.list {
  margin-top: 20px;
  transition: 0.3s ease-in;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > li {
    font-size: 24px;
    font-weight: 700;
  }
  a {
    padding-left: 20px;
    font-size: 1.1rem;
    color: rgba(42, 42, 42, 0.685);
    
  }

  a:hover {
    color: #004670;
  }
}



@media (max-width: 1000px) {
  .doc {
    font-size: 20px;
    width: auto;
    text-align: left;
  }
}
