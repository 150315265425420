.footer {
  background-color: #004669;
  color: white;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  min-height: 300px;
}

.wrapperLogo {
  // margin-left: 5%;
  margin-left: 10%;
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
  width: 200px;
  height: max-content;
}

.wrapperContacts {
  // margin-right: 5%;
  margin-right: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 100px;
}

.contacts {
  width: max-content;
}

.title {
  margin-left: 30px;
  margin-top: 20px;
  font-weight: 600;
}

.text {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.arrow {
  cursor: pointer;
  width: 100px;
  height: 100px;
}

@media (max-width: 768px) {
  .footer {
    font-size: 16px;
  }

  .text {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .wrapperContacts {
    gap: 50px;
  }

  .arrow {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
  }
  .wrapperContacts,
  .wrapperLogo {
    margin-top: 20px;
    width: 100%;
    justify-content: unset;
    margin-right: 0;
    margin-left: 5%;
  }
  .wrapperContacts {
    flex-direction: column;
    align-items: start;
  }
  .arrow {
    position: relative;
    left: 75%;
  }
}
