.header {
  min-height: calc(100vh);
  background-image: url('../../../../images/MasterPlan/header.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  cursor: pointer;
  width: 200px;
  position: absolute;
  top: 1%;
  left: 2%;
}

.textWrapper {
  // padding: 220px 5% 50px;
  padding: 220px 10% 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: calc(100vh);
  color: white;

  h1 {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
  }

  p {
    margin-bottom: 10px;
    // text-indent: 3em;
    font-size: 20px;
  }
}

@media (max-width: 1350px) {
  .textWrapper {
    // padding: 200px 5% 50px;
    padding: 200px 10% 50px;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }
}

@media (max-width: 1200px) {
  .logo {
    display: none;
  }
  .textWrapper {
    // padding: 0 5%;
    padding: 0 10%;
  }
}

@media (max-width: 1000px) {
  .textWrapper {
    // padding: 100px 5% 50px;
    padding: 100px 10% 50px;

    h1 {
      font-size: 20px;
    }
    p {
      font-size: 18px;
    }
  }
}
