.burger {
  width: 36px;
  height: 26px;
  outline: none;
  background-color: transparent;
  border: none;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  position: fixed;
  right: 30px;
  top: 40px;
  z-index: 6001;

  display: none;

  @media (max-width: 1200px) {
    display: block;
    position: absolute;
  }

  & span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #00405b;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }

    &:nth-child(4) {
      top: 20px;
    }
  }

  &.open {
    // top: 90px;
    right: 35px;

    span {
      background-color: white;
    }

    &::before {
      border-radius: 0;
      top: -20px;
      right: -20px;
      height: 100vh;
      width: 65vw;
    }
  }

  &.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
}
