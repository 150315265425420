.item {
    width: 100%;
    min-width: 208px;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 36px;

    @media (max-width: 1000px) {
        margin-right: 0;  
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .img {
        width: 100%;

        // height: 100%;
        margin-bottom: 12px;

        background-position: 50% 0%;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 1000px) {
            width: 208px;
            height: 208px;
        }
        @media (max-width: 768px) {
            width: 114px;
            height: 114px;
            margin-bottom: 0;
        }
    }
    .textWrapper {
        display: flex;
        flex-direction: column;
        @media (max-width: 1000px) {
            margin-right: 0;  
            width: calc(100% - 250px);
        }
        @media (max-width: 768px) {
            margin-right: 0;  
            width: calc(100% - 132px);
        }
        p {
            font-size: 17;
            font-weight: 600;
            margin-bottom: 12px;

            @media (max-width: 1000px) {
                margin-bottom: 9px;
                font-size: 30px;
            }
            @media (max-width: 768px) {
                margin-bottom: 9px;
                font-size: 15px;
                line-height: 17px;
            }
        }
    
        span, a {
            font-size: 18px;
            font-weight: 400;
            color: #92A7B1;
    
            &.phone {
                font-size: 14px;
                @media (max-width: 1000px) {
                    font-size: 25px;
                }
                @media (max-width: 768px) {
                    font-size: 13px;
                }
            }
    
            &.position {
                font-size: 15px;
                color: #00405B;
                margin-bottom: 13px;
                @media (max-width: 1000px) {
                    margin-bottom: 9px;
                    font-size: 25px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 9px;
                    font-size: 13px;
                }
            }
    
            &.email {
                font-size: 14px;
                @media (max-width: 1000px) {
                    font-size: 25px;
                }
                @media (max-width: 768px) {
                    font-size: 12px;
                }
            }
        }
    }
}