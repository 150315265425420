.item {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 300px;
  width: 100%;

  img {
    width: 60%;
    // height: 100px;
  }
}