.mainInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-color: transparent;
  font-family: solomon;

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    grid-area: 1 / 1 / 2 / 5;

    p {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      color: #00405b;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  &:hover {
    background-color: #eff2f4;
  }

  &.pressed {
    background-color: #eff2f4;
  }
}

.direction {
  padding: 15px;
  font-size: 16px;
  color: #00405b;
  font-weight: 400;
  width: 100%;

  @media (max-width: 768px) {
    // background-color: #00405b;
    // color: #ffffff;
    border-bottom: 1px solid #00405b;
    font-weight: 600;
    text-align: center;
    // margin-bottom: 30px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 13px;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 300px;
    width: 2px;
    left: 66.6%;
    bottom: -4px;
    background-color: #92a7b1;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.organization {
  text-align: left;
  padding: 15px;
  font-size: 16px;
  color: #00405b;
  font-weight: 400;
  width: 100%;

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 13px;
  }

  @media (min-width: 1000px) {
    // padding-left: 85px;
  }

  @media (max-width: 768px) {
    background-color: #00405b;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    // margin-bottom: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 300px;
    width: 2px;
    left: 33.2%;
    bottom: -4px;
    background-color: #92a7b1;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.contact {
  padding: 15px 30px 15px 15px;
  text-align: left;

  @media (max-width: 768px) {
    margin-bottom: 35px;
  }

  p {
    display: block;
    font-size: 16px;
    font-style: normal;
    color: #00405b;
    margin-bottom: 8px;
    position: relative;
    margin-left: 20px;

    user-select: text;

    @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 13px;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 4px;
      width: 6px;
      height: 6px;
      background-color: #ff2e17;
      border-radius: 50%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.name,
.tel,
.mail,
.locate {
  display: block;
  font-size: 16px;
  font-style: normal;
  color: #00405b;
  margin-bottom: 8px;
  position: relative;
  margin-left: 20px;

  &::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 4px;
    width: 6px;
    height: 6px;
    background-color: #ff2e17;
    border-radius: 50%;
  }
}

.locate {
  margin-bottom: 0;
}
