.content {
  // user-select: ;
  ul {
    border: 2px solid #00405b;

    @media (max-width: 768px) {
      border: none;
    }
  }

  li {
    &:last-child {
      border-bottom: none;

      @media (max-width: 768px) {
        border-bottom: 1px solid #00405b;
      }
    }
  }
}
