.link {
  margin-bottom: 20px;
  padding-left: 70px;
  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-around;
    padding-left: 0px;
  }

  a.inner_link {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.5;
    color: #00405b;
    display: block;
    width: auto;

    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
    }

    &:hover {
      color: #ff2e17;
    }

    &.pressed {
      color: #ff2e17;
    }
  }
}
