.mainDescription {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    margin-bottom: 85px;

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }
}

.workersWrapper {
    display: grid; 
    grid-template-columns: repeat(auto-fill, minmax(208px, 1fr));
    column-gap: 51px;

    
    

    @media (max-width: 1000px) {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;   
    }
}

