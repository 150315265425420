.slider {
  width: 300px;
  // height: 500px;
  position: relative;

  

  @media (max-width: 1000px) {
    width: 700px;
    // height: 400px;  
    margin: 0 auto 40px;
  }

  @media (max-width: 768px) {
    width: 300px;
    height: 280px;  
    margin: 0 auto 40px;
  }
  
  .decorImgae {
    position: absolute;
    left: -131px;

    width: 250px;
    height: auto;

    @media (max-width: 1000px) {
      width: 128px;
      left: -67px;
    }
  }
}
