.table {
  border: 2px solid #00405b;

  @media (max-width: 768px) {
    border: none;
  }
}

.item {
  border-bottom: 2px solid #92a7b1;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    padding-top: 0;
    margin-bottom: 30px;
    border: 1px solid #00405b;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    border-bottom: none;
    @media (max-width: 768px) {
      border-bottom: 1px solid #00405b;
    }
  }

  &:hover {
    background-color: #eff2f4;
  }

  .inner {
    display: grid;
    grid-template-columns: 20% 30% 20% 15% 15%;
    align-items: center;
    text-align: center;
    background-color: transparent;
    position: relative;
    // overflow: hidden;

    p {
      width: 100%;
      position: relative;
      font-family: solomon;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.13;
      color: #00405b;
      padding: 0 15px 15px;
      text-align: center;

      &.sorted {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 10px;
          transition: .3s;
          width: 18px;

          cursor: pointer;
        }
      }

      @media (max-width: 1100px) {
        padding: 0 0 15px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -1px;
        width: 2px;
        height: calc(150% + 200px);
        background-color: #92a7b1;
        transform: translateY(-50%);
      }

      &:last-of-type {
        border-right: none;

        &::after {
          display: none;
        }
      }

      &.dates {
        display: flex;
        flex-direction: column;

        span {
          &:nth-of-type(2) {
            color: #ff2e17;
            margin: 5px 0;
          }
        }
      }
    }
  }

  &:last-of-type {
    & > div {
      border-bottom: 0;
    }
  }

  &.head {
    padding-top: 22px;
    padding-bottom: 20px;
    background-color: #92a7b1;

    @media (max-width: 768px) {
      display: none;
    }

    .inner {
      border-bottom: none;

      p {
        border-bottom: none;
        position: relative;
        color: white;
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 0;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -1px;
          width: 2px;
          height: 100px;
          background-color: #fff;
          transform: translateY(-50%);
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
